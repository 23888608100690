import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import AsideLayout from '../components/AsideLayout'

import SEO from '../components/seo'

const renderContact = () => ({ site }) => {
  const { siteMetadata } = site;
  const { phoneNumber, emailAddress } = siteMetadata;

  return (
    <Layout>
      <SEO title="Contact us" />
      <AsideLayout
        aside={ <img src="/eva.jpg" className="rounded shadow" /> }
      >
        <h1>Contact us</h1>
        <p>Use one of the contact methods below to get in touch!</p>
        <ul>
          <li>Mobile: <a href={ `tel: ${phoneNumber}`}>{ phoneNumber }</a></li>
          <li>Email: <a href={ `mailto: ${emailAddress}`}>{ emailAddress }</a></li>
          <li>FB Messenger: <a href="https://m.me/wilderborngsd">@wilderborngsd</a></li>
        </ul>
      </AsideLayout>
    </Layout>
  )
}

const Contact = () => (
  <StaticQuery
    query={
      graphql`
        query {
          site {
            siteMetadata {
              phoneNumber,
              emailAddress,
            }
          }
        }
      `
    }
    render={ renderContact() }
  />
)

export default Contact
